@import '~@bottomless/common/style/common';

.layout-panel {
  .layout-content {
    max-width: var(--page-width, 120em);
    flex: 10 1 auto;
  }

  .nav {
    padding: 0;
  }

  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-link.active {
    font-weight: 600;

    svg {
      stroke-width: 2.5px;
    }
  }

  .layout-navigation,
  .nav-empty {
    flex: 1 0;
  }

  .layout-navigation {
    min-width: 175px;
  }

  .main-navigation {
    .nav-link svg {
      width: 16px;
      height: 16px;
    }

    &-notification {
      top: calc(50% - 9px);
      right: 4px;
      background: $danger;
      color: $white;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .layout-content {
    padding: 0 1.5rem;
  }

  .navigation-button {
    box-shadow: none !important;
    text-decoration: none !important;
  }

  .account-dropdown-image {
    border-radius: 4px;

    .lazy-load-image-background {
      display: flex !important;
    }
  }

  @media screen and (min-width: 750px) {
    .layout-content,
    .layout-navigation {
      padding: 0 5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .layout-navigation {
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 80px;
    flex-direction: column;

    .account-dropdown-wrapper {
      max-width: 50%;

      hr {
        display: none;
      }
    }

    .account-dropdown {
      max-width: 100%;
    }

    .layout-navigation {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1em;
      padding: 0 1.5rem;
    }

    .main-navigation {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1010;
      background: $body-color;
      color: $white;

      &-account {
        height: 24px;
        width: 24px;
        background: $white;
        border-radius: 4px;
      }

      &-notification {
        top: 6px;
        right: calc(50% - 22px);
      }

      .nav-link {
        color: $white;
        flex-direction: column;
        padding-top: 1em;
        padding-bottom: 0.75em;
        font-size: 0.75em;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .nav-item {
      flex: 1;
    }
  }
}
