:host,
:root {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #232323;
  text-align: left;
  background-color: var(--color-background, #fff);
  contain: content;
  -webkit-tap-highlight-color: transparent;
}

@import '~bootstrap/scss/functions';
@import './style/variable-overrides';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

@import '~@bottomless/common/style/common';
@import './style/variables';

@import '~fork-awesome/css/fork-awesome.min.css';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-datepicker/src/stylesheets/datepicker.scss';
@import '~react-lazy-load-image-component/src/effects/opacity.css';

.text-blue {
  color: #0000ee;
}

.text-underline {
  text-decoration: underline;
}

.text-strikethrough {
  text-decoration: line-through;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary, $primary);
  border-color: var(--primary, $primary);
}

.min-w-fit {
  min-width: fit-content !important;
}

.btn-primary:hover,
.btn-outline-primary {
  color: var(--primary, $primary);
  background-color: transparent;
  border-color: var(--primary, $primary);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s infinite linear;
}

select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23232323' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.table.table-spaced {
  thead th,
  td {
    padding: 1.25em 0.75em;
  }
}

.toasts {
  .toast {
    color: $primary !important;
    border-color: $primary !important;
  }
}

.modal {
  overflow: scroll;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.autocomplete-dropdown {
  margin-top: -1em;
  margin-bottom: 1em;
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 1000;
  height: 1px;

  .autocomplete-inner {
    border-left: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
    border-bottom: 1px solid $input-border-color;

    > div {
      background: $white;
      padding: 0.3em 0.5em;
      cursor: pointer;
      font-size: 0.9em;
    }
  }
}

.modal-title {
  padding-top: 1.5em;
}

.modal-body {
  padding-top: 1em;
  padding-bottom: 3em;
}

.mw-300 {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.mw-360 {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

stripe-elements::part(stripe) {
  border-radius: 0;
  border: 1px solid $input-border-color;
  box-shadow: none;
}

stripe-elements {
  min-height: 0;
}

.datepicker-inline {
  &.react-datepicker {
    border: none;
    display: block;
    font-size: 0.9em;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5em;
    line-height: 2.5em;
    margin: 0.4em;
  }

  .react-datepicker__header {
    background-color: transparent;
  }

  .react-datepicker__navigation {
    top: 5px;
  }
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 1.875em;
  }

  h4 {
    font-size: 1.25em;
  }
}

@include media-breakpoint-up(md) {
  .toasts {
    top: initial !important;
    bottom: 20px !important;
    right: 20px !important;
  }

  .modal-header {
    justify-content: center;

    .close {
      position: absolute;
      right: 0.75em;
      top: 0.75em;
    }
  }

  .row.row-spaced {
    margin-left: -20px;
    margin-right: -20px;

    > .col-1,
    > .col-2,
    > .col-3,
    > .col-4,
    > .col-5,
    > .col-6,
    > .col-7,
    > .col-8,
    > .col-9,
    > .col-10,
    > .col-11,
    > .col-12,
    > .col,
    > .col-auto,
    > .col-sm-1,
    > .col-sm-2,
    > .col-sm-3,
    > .col-sm-4,
    > .col-sm-5,
    > .col-sm-6,
    > .col-sm-7,
    > .col-sm-8,
    > .col-sm-9,
    > .col-sm-10,
    > .col-sm-11,
    > .col-sm-12,
    > .col-sm,
    > .col-sm-auto,
    > .col-md-1,
    > .col-md-2,
    > .col-md-3,
    > .col-md-4,
    > .col-md-5,
    > .col-md-6,
    > .col-md-7,
    > .col-md-8,
    > .col-md-9,
    > .col-md-10,
    > .col-md-11,
    > .col-md-12,
    > .col-md,
    > .col-md-auto,
    > .col-lg-1,
    > .col-lg-2,
    > .col-lg-3,
    > .col-lg-4,
    > .col-lg-5,
    > .col-lg-6,
    > .col-lg-7,
    > .col-lg-8,
    > .col-lg-9,
    > .col-lg-10,
    > .col-lg-11,
    > .col-lg-12,
    > .col-lg,
    > .col-lg-auto,
    > .col-xl-1,
    > .col-xl-2,
    > .col-xl-3,
    > .col-xl-4,
    > .col-xl-5,
    > .col-xl-6,
    > .col-xl-7,
    > .col-xl-8,
    > .col-xl-9,
    > .col-xl-10,
    > .col-xl-11,
    > .col-xl-12,
    > .col-xl,
    > .col-xl-auto {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .row.row-spaced {
    margin-left: -45px;
    margin-right: -45px;

    > .col-1,
    > .col-2,
    > .col-3,
    > .col-4,
    > .col-5,
    > .col-6,
    > .col-7,
    > .col-8,
    > .col-9,
    > .col-10,
    > .col-11,
    > .col-12,
    > .col,
    > .col-auto,
    > .col-sm-1,
    > .col-sm-2,
    > .col-sm-3,
    > .col-sm-4,
    > .col-sm-5,
    > .col-sm-6,
    > .col-sm-7,
    > .col-sm-8,
    > .col-sm-9,
    > .col-sm-10,
    > .col-sm-11,
    > .col-sm-12,
    > .col-sm,
    > .col-sm-auto,
    > .col-md-1,
    > .col-md-2,
    > .col-md-3,
    > .col-md-4,
    > .col-md-5,
    > .col-md-6,
    > .col-md-7,
    > .col-md-8,
    > .col-md-9,
    > .col-md-10,
    > .col-md-11,
    > .col-md-12,
    > .col-md,
    > .col-md-auto,
    > .col-lg-1,
    > .col-lg-2,
    > .col-lg-3,
    > .col-lg-4,
    > .col-lg-5,
    > .col-lg-6,
    > .col-lg-7,
    > .col-lg-8,
    > .col-lg-9,
    > .col-lg-10,
    > .col-lg-11,
    > .col-lg-12,
    > .col-lg,
    > .col-lg-auto,
    > .col-xl-1,
    > .col-xl-2,
    > .col-xl-3,
    > .col-xl-4,
    > .col-xl-5,
    > .col-xl-6,
    > .col-xl-7,
    > .col-xl-8,
    > .col-xl-9,
    > .col-xl-10,
    > .col-xl-11,
    > .col-xl-12,
    > .col-xl,
    > .col-xl-auto {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}

#bottomless-app .toasts .toast {
  border-color: #232323 !important;
  color: #232323 !important;
}

@include media-breakpoint-down(sm) {
  .mw-300 {
    max-width: none;
  }

  #bottomless-app .toasts {
    bottom: 0 !important;
    max-width: 100% !important;
    padding: 0 20px !important;
  }

  .modal.show {
    .modal-dialog {
      flex: 1;
      margin-bottom: 80px;
    }

    .modal-content {
      min-height: calc(100vh - 20px);
    }
  }
}
