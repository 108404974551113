$font-family-sans-serif: 'Assistant', sans-serif;
$headings-font-weight: 400;

$table-th-font-weight: 600;

$primary: #333;

// React datepicker
$datepicker__border-radius: 0.3em;
$datepicker__day-margin: 0.166em;
$datepicker__font-size: 0.8em;
$datepicker__item-size: 1.7em;
$datepicker__margin: 0.4em;
$datepicker__navigation-size: 0.45em;
