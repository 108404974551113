$primary: #333;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$modal-content-border-width: 0;

$black: #000 !default;

$spacer: 1em !default;

$paragraph-margin-bottom: 1em !default;

$border-radius: 0.25em !default;
$border-radius-lg: 0.3em !default;
$border-radius-sm: 0.2em !default;

$rounded-pill: 50em !default;

$box-shadow-sm: 0 0.125em 0.25em rgba($black, 0.075) !default;
$box-shadow: 0 0.5em 1em rgba($black, 0.15) !default;
$box-shadow-lg: 0 1em 3em rgba($black, 0.175) !default;

$font-size-base: 1em !default; // Assumes the browser default, typically `16px`

$display1-size: 6em !default;
$display2-size: 5.5em !default;
$display3-size: 4.5em !default;
$display4-size: 3.5em !default;

$kbd-box-shadow: inset 0 -0.1em 0 rgba($black, 0.25) !default;

$list-inline-padding: 0.5em !default;

$table-cell-padding: 0.75em !default;
$table-cell-padding-sm: 0.3em !default;

$input-btn-padding-y: 0.375em !default;
$input-btn-padding-x: 0.75em !default;

$input-btn-focus-width: 0.2em !default;

$input-btn-padding-y-sm: 0.25em !default;
$input-btn-padding-x-sm: 0.5em !default;

$input-btn-padding-y-lg: 0.5em !default;
$input-btn-padding-x-lg: 1em !default;

$btn-block-spacing-y: 0.5em !default;

$label-margin-bottom: 0.5em !default;

$form-text-margin-top: 0.25em !default;

$form-check-input-gutter: 1.25em !default;
$form-check-input-margin-y: 0.3em !default;
$form-check-input-margin-x: 0.25em !default;

$form-check-inline-margin-x: 0.75em !default;
$form-check-inline-input-margin-x: 0.3125em !default;

$form-group-margin-bottom: 1em !default;

$custom-control-gutter: 0.5em !default;
$custom-control-spacer-x: 1em !default;

$custom-control-indicator-size: 1em !default;

$custom-select-indicator-padding: 1em !default; // Extra padding to account for the presence of the background-image based indicator

$custom-range-track-height: 0.5em !default;

$custom-range-track-border-radius: 1em !default;
$custom-range-track-box-shadow: inset 0 0.25em 0.25em rgba($black, 0.1) !default;

$custom-range-thumb-width: 1em !default;

$custom-range-thumb-border-radius: 1em !default;
$custom-range-thumb-box-shadow: 0 0.1em 0.25em rgba($black, 0.1) !default;

$nav-link-padding-y: 0.5em !default;
$nav-link-padding-x: 1em !default;

$navbar-nav-link-padding-x: 0.5em !default;

$navbar-toggler-padding-y: 0.25em !default;
$navbar-toggler-padding-x: 0.75em !default;

$dropdown-min-width: 10em !default;

$dropdown-padding-y: 0.5em !default;
$dropdown-spacer: 0.125em !default;

$dropdown-box-shadow: 0 0.5em 1em rgba($black, 0.175) !default;

$dropdown-item-padding-y: 0.25em !default;
$dropdown-item-padding-x: 1.5em !default;

$pagination-padding-y: 0.5em !default;
$pagination-padding-x: 0.75em !default;
$pagination-padding-y-sm: 0.25em !default;
$pagination-padding-x-sm: 0.5em !default;
$pagination-padding-y-lg: 0.75em !default;
$pagination-padding-x-lg: 1.5em !default;

$jumbotron-padding: 2em !default;

$card-spacer-y: 0.75em !default;
$card-spacer-x: 1.25em !default;

$card-img-overlay-padding: 1.25em !default;

$card-columns-gap: 1.25em !default;

$tooltip-padding-y: 0.25em !default;
$tooltip-padding-x: 0.5em !default;

$tooltip-arrow-width: 0.8em !default;
$tooltip-arrow-height: 0.4em !default;

$popover-box-shadow: 0 0.25em 0.5em rgba($black, 0.2) !default;

$popover-header-padding-y: 0.5em !default;
$popover-header-padding-x: 0.75em !default;

$popover-arrow-width: 1em !default;
$popover-arrow-height: 0.5em !default;

$toast-padding-x: 0.75em !default;
$toast-padding-y: 0.25em !default;
$toast-font-size: 0.875em !default;

$toast-border-radius: 0.25em !default;
$toast-box-shadow: 0 0.25em 0.75em rgba($black, 0.1) !default;

$badge-pill-border-radius: 10em !default;

$modal-inner-padding: 1em !default;

$modal-footer-margin-between: 0.5em !default;

$modal-dialog-margin: 0.5em !default;
$modal-dialog-margin-y-sm-up: 1.75em !default;

$modal-content-box-shadow-xs: 0 0.25em 0.5em rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5em 1em rgba($black, 0.5) !default;

$modal-header-padding-y: 1em !default;
$modal-header-padding-x: 1em !default;

$alert-padding-y: 0.75em !default;
$alert-padding-x: 1.25em !default;
$alert-margin-bottom: 1em !default;

$progress-height: 1em !default;

$progress-box-shadow: inset 0 0.1em 0.1em rgba($black, 0.1) !default;

$list-group-item-padding-y: 0.75em !default;
$list-group-item-padding-x: 1.25em !default;

$thumbnail-padding: 0.25em !default;

$breadcrumb-padding-y: 0.75em !default;
$breadcrumb-padding-x: 1em !default;
$breadcrumb-item-padding: 0.5em !default;

$breadcrumb-margin-bottom: 1em !default;

$spinner-width: 2em !default;

$spinner-width-sm: 1em !default;

$kbd-padding-y: 0.2em !default;
$kbd-padding-x: 0.4em !default;
